// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-section {
  background: rgb(2, 0, 36);
  background: linear-gradient(135deg, rgba(2, 0, 36, 0.6671043417) 56%, rgb(62, 134, 111) 100%);
}

.error-botico-logo {
  max-width: 300px;
  opacity: 0.2;
}`, "",{"version":3,"sources":["webpack://./src/pages/ErrorPage/styles.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,6FAAA;AACF;;AAEA;EACE,gBAAA;EACA,YAAA;AACF","sourcesContent":[".error-section {\n  background: rgb(2,0,36);\n  background: linear-gradient(135deg, rgba(2,0,36,0.6671043417366946) 56%, rgba(62,134,111,1) 100%);\n}\n\n.error-botico-logo {\n  max-width: 300px;\n  opacity: 0.2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
