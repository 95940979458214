import Login from "./Login/Login"
import ErrorPage from "./ErrorPage/ErrorPage";
import LandingPage from "./LandingPage/LandingPage";
import Career from "./LandingPage/Career";

export {
    ErrorPage,
    Login,
    LandingPage,
    Career
}