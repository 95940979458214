// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-box {
  flex-grow: 1;
}
.header-box .app-header-bar {
  background: #181A20;
}
.header-box .header-action-bar {
  display: flex;
  width: 100%;
}
.header-box .header-action-bar .logo {
  flex: 1 1;
  height: 40px;
}
.header-box .header-action-bar .logo img {
  max-width: 100%;
}

.header-menu {
  display: flex;
  flex: 1 1;
  gap: 20px;
  margin-left: 35px;
  text-transform: uppercase;
}
.header-menu .header-menu-wrapper {
  position: relative;
  padding: 20px 0;
}
.header-menu .submenu {
  text-transform: uppercase;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  background: #000000;
  padding: 5px;
  min-width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/styles.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AAAE;EACE,mBAAA;AAEJ;AACE;EACE,aAAA;EACA,WAAA;AACJ;AAAI;EACE,SAAA;EACA,YAAA;AAEN;AADM;EACE,eAAA;AAGR;;AAIA;EACE,aAAA;EACA,SAAA;EACA,SAAA;EACA,iBAAA;EACA,yBAAA;AADF;AAGE;EACE,kBAAA;EACA,eAAA;AADJ;AAIE;EACE,yBAAA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;EACA,aAAA;EACA,wCAAA;EACA,mBAAA;EACA,YAAA;EACA,gBAAA;AAFJ","sourcesContent":[".header-box {\n  flex-grow: 1;\n  .app-header-bar {\n    background: #181A20;\n  }\n\n  .header-action-bar {\n    display: flex;\n    width: 100%;\n    .logo {\n      flex: 1;\n      height: 40px;\n      img {\n        max-width: 100%;\n      }\n    }\n  }\n}\n\n\n.header-menu {\n  display: flex;\n  flex: 1;\n  gap: 20px;\n  margin-left: 35px;\n  text-transform: uppercase;\n\n  .header-menu-wrapper {\n    position: relative;\n    padding: 20px 0;\n  }\n\n  .submenu {\n    text-transform: uppercase;\n    position: absolute;\n    top: 100%;\n    left: 0;\n    z-index: 1000;\n    box-shadow: 0 2px 5px rgba(0,0,0,0.2);\n    background: #000000;\n    padding: 5px;\n    min-width: 200px;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
